/* Component bubble */
@-webkit-keyframes move {
  20% {
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translate3d(50vw, 50vh, -1000px);
    -moz-transform: translate3d(50vw, 50vh, -1000px);
    -ms-transform: translate3d(50vw, 50vh, -1000px);
    -o-transform: translate3d(50vw, 50vh, -1000px);
    transform: translate3d(50vw, 50vh, -1000px);
    opacity: 0;
  }
}
@keyframes move {
  20% {
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translate3d(50vw, 50vh, -1000px);
    -moz-transform: translate3d(50vw, 50vh, -1000px);
    -ms-transform: translate3d(50vw, 50vh, -1000px);
    -o-transform: translate3d(50vw, 50vh, -1000px);
    transform: translate3d(50vw, 50vh, -1000px);
    opacity: 0;
  }
}
.bubble-content {
  position: absolute;
  top: 0;
  -moz-perspective: 6000px;
  -ms-perspective: 6000px;
  -webkit-perspective: 6000px;
  perspective: 6000px;
}
.bubble {
  position: absolute;
  background: black;
  opacity: 0;
  border-radius: 50%;
  -webkit-animation: move 3s infinite;
  -moz-animation: move 3s infinite;
  -o-animation: move 3s infinite;
  animation: move 3s infinite;
}
.bubble:nth-child(1) {
  height: 5px;
  width: 5px;
  margin-left: -5px/2;
  margin-top: -5px/2;
  -webkit-animation-delay: -0.2s;
  -moz-animation-delay: -0.2s;
  -o-animation-delay: -0.2s;
  animation-delay: -0.2s;
  -webkit-transform: translate3d(64vw, 8vh, 287px);
  -moz-transform: translate3d(64vw, 8vh, 287px);
  -ms-transform: translate3d(64vw, 8vh, 287px);
  -o-transform: translate3d(64vw, 8vh, 287px);
  transform: translate3d(64vw, 8vh, 287px);
  background: #4d26d9;
}
.bubble:nth-child(2) {
  height: 27px;
  width: 27px;
  margin-left: -27px/2;
  margin-top: -27px/2;
  -webkit-animation-delay: -0.4s;
  -moz-animation-delay: -0.4s;
  -o-animation-delay: -0.4s;
  animation-delay: -0.4s;
  -webkit-transform: translate3d(73vw, 15vh, 95px);
  -moz-transform: translate3d(73vw, 15vh, 95px);
  -ms-transform: translate3d(73vw, 15vh, 95px);
  -o-transform: translate3d(73vw, 15vh, 95px);
  transform: translate3d(73vw, 15vh, 95px);
  background: #d92659;
}
.bubble:nth-child(3) {
  height: 28px;
  width: 28px;
  margin-left: -28px/2;
  margin-top: -28px/2;
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  -o-animation-delay: -0.6s;
  animation-delay: -0.6s;
  -webkit-transform: translate3d(95vw, 94vh, 179px);
  -moz-transform: translate3d(95vw, 94vh, 179px);
  -ms-transform: translate3d(95vw, 94vh, 179px);
  -o-transform: translate3d(95vw, 94vh, 179px);
  transform: translate3d(95vw, 94vh, 179px);
  background: #26d974;
}
.bubble:nth-child(4) {
  height: 25px;
  width: 25px;
  margin-left: -25px/2;
  margin-top: -25px/2;
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s;
  -webkit-transform: translate3d(51vw, 61vh, 334px);
  -moz-transform: translate3d(51vw, 61vh, 334px);
  -ms-transform: translate3d(51vw, 61vh, 334px);
  -o-transform: translate3d(51vw, 61vh, 334px);
  transform: translate3d(51vw, 61vh, 334px);
  background: #29d926;
}
.bubble:nth-child(5) {
  height: 19px;
  width: 19px;
  margin-left: -19px/2;
  margin-top: -19px/2;
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
  -webkit-transform: translate3d(20vw, 68vh, 283px);
  -moz-transform: translate3d(20vw, 68vh, 283px);
  -ms-transform: translate3d(20vw, 68vh, 283px);
  -o-transform: translate3d(20vw, 68vh, 283px);
  transform: translate3d(20vw, 68vh, 283px);
  background: #3bd926;
}
.bubble:nth-child(6) {
  height: 17px;
  width: 17px;
  margin-left: -17px/2;
  margin-top: -17px/2;
  -webkit-animation-delay: -1.2s;
  -moz-animation-delay: -1.2s;
  -o-animation-delay: -1.2s;
  animation-delay: -1.2s;
  -webkit-transform: translate3d(92vw, 52vh, 167px);
  -moz-transform: translate3d(92vw, 52vh, 167px);
  -ms-transform: translate3d(92vw, 52vh, 167px);
  -o-transform: translate3d(92vw, 52vh, 167px);
  transform: translate3d(92vw, 52vh, 167px);
  background: #d92685;
}
.bubble:nth-child(7) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -1.4s;
  -moz-animation-delay: -1.4s;
  -o-animation-delay: -1.4s;
  animation-delay: -1.4s;
  -webkit-transform: translate3d(98vw, 37vh, 81px);
  -moz-transform: translate3d(98vw, 37vh, 81px);
  -ms-transform: translate3d(98vw, 37vh, 81px);
  -o-transform: translate3d(98vw, 37vh, 81px);
  transform: translate3d(98vw, 37vh, 81px);
  background: #26d982;
}
.bubble:nth-child(8) {
  height: 23px;
  width: 23px;
  margin-left: -23px/2;
  margin-top: -23px/2;
  -webkit-animation-delay: -1.6s;
  -moz-animation-delay: -1.6s;
  -o-animation-delay: -1.6s;
  animation-delay: -1.6s;
  -webkit-transform: translate3d(80vw, 30vh, 41px);
  -moz-transform: translate3d(80vw, 30vh, 41px);
  -ms-transform: translate3d(80vw, 30vh, 41px);
  -o-transform: translate3d(80vw, 30vh, 41px);
  transform: translate3d(80vw, 30vh, 41px);
  background: #26d98b;
}
.bubble:nth-child(9) {
  height: 27px;
  width: 27px;
  margin-left: -27px/2;
  margin-top: -27px/2;
  -webkit-animation-delay: -1.8s;
  -moz-animation-delay: -1.8s;
  -o-animation-delay: -1.8s;
  animation-delay: -1.8s;
  -webkit-transform: translate3d(30vw, 1vh, 496px);
  -moz-transform: translate3d(30vw, 1vh, 496px);
  -ms-transform: translate3d(30vw, 1vh, 496px);
  -o-transform: translate3d(30vw, 1vh, 496px);
  transform: translate3d(30vw, 1vh, 496px);
  background: #d99426;
}
.bubble:nth-child(10) {
  height: 25px;
  width: 25px;
  margin-left: -25px/2;
  margin-top: -25px/2;
  -webkit-animation-delay: -2s;
  -moz-animation-delay: -2s;
  -o-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-transform: translate3d(87vw, 34vh, 476px);
  -moz-transform: translate3d(87vw, 34vh, 476px);
  -ms-transform: translate3d(87vw, 34vh, 476px);
  -o-transform: translate3d(87vw, 34vh, 476px);
  transform: translate3d(87vw, 34vh, 476px);
  background: #d92668;
}
.bubble:nth-child(11) {
  height: 22px;
  width: 22px;
  margin-left: -22px/2;
  margin-top: -22px/2;
  -webkit-animation-delay: -2.2s;
  -moz-animation-delay: -2.2s;
  -o-animation-delay: -2.2s;
  animation-delay: -2.2s;
  -webkit-transform: translate3d(65vw, 31vh, 58px);
  -moz-transform: translate3d(65vw, 31vh, 58px);
  -ms-transform: translate3d(65vw, 31vh, 58px);
  -o-transform: translate3d(65vw, 31vh, 58px);
  transform: translate3d(65vw, 31vh, 58px);
  background: #2650d9;
}
.bubble:nth-child(12) {
  height: 21px;
  width: 21px;
  margin-left: -21px/2;
  margin-top: -21px/2;
  -webkit-animation-delay: -2.4s;
  -moz-animation-delay: -2.4s;
  -o-animation-delay: -2.4s;
  animation-delay: -2.4s;
  -webkit-transform: translate3d(17vw, 30vh, 242px);
  -moz-transform: translate3d(17vw, 30vh, 242px);
  -ms-transform: translate3d(17vw, 30vh, 242px);
  -o-transform: translate3d(17vw, 30vh, 242px);
  transform: translate3d(17vw, 30vh, 242px);
  background: #62d926;
}
.bubble:nth-child(13) {
  height: 11px;
  width: 11px;
  margin-left: -11px/2;
  margin-top: -11px/2;
  -webkit-animation-delay: -2.6s;
  -moz-animation-delay: -2.6s;
  -o-animation-delay: -2.6s;
  animation-delay: -2.6s;
  -webkit-transform: translate3d(76vw, 5vh, 478px);
  -moz-transform: translate3d(76vw, 5vh, 478px);
  -ms-transform: translate3d(76vw, 5vh, 478px);
  -o-transform: translate3d(76vw, 5vh, 478px);
  transform: translate3d(76vw, 5vh, 478px);
  background: #be26d9;
}
.bubble:nth-child(14) {
  height: 6px;
  width: 6px;
  margin-left: -6px/2;
  margin-top: -6px/2;
  -webkit-animation-delay: -2.8s;
  -moz-animation-delay: -2.8s;
  -o-animation-delay: -2.8s;
  animation-delay: -2.8s;
  -webkit-transform: translate3d(95vw, 7vh, 440px);
  -moz-transform: translate3d(95vw, 7vh, 440px);
  -ms-transform: translate3d(95vw, 7vh, 440px);
  -o-transform: translate3d(95vw, 7vh, 440px);
  transform: translate3d(95vw, 7vh, 440px);
  background: #b226d9;
}
.bubble:nth-child(15) {
  height: 30px;
  width: 30px;
  margin-left: -30px/2;
  margin-top: -30px/2;
  -webkit-animation-delay: -3s;
  -moz-animation-delay: -3s;
  -o-animation-delay: -3s;
  animation-delay: -3s;
  -webkit-transform: translate3d(18vw, 49vh, 194px);
  -moz-transform: translate3d(18vw, 49vh, 194px);
  -ms-transform: translate3d(18vw, 49vh, 194px);
  -o-transform: translate3d(18vw, 49vh, 194px);
  transform: translate3d(18vw, 49vh, 194px);
  background: #d94d26;
}
.bubble:nth-child(16) {
  height: 21px;
  width: 21px;
  margin-left: -21px/2;
  margin-top: -21px/2;
  -webkit-animation-delay: -3.2s;
  -moz-animation-delay: -3.2s;
  -o-animation-delay: -3.2s;
  animation-delay: -3.2s;
  -webkit-transform: translate3d(98vw, 95vh, 367px);
  -moz-transform: translate3d(98vw, 95vh, 367px);
  -ms-transform: translate3d(98vw, 95vh, 367px);
  -o-transform: translate3d(98vw, 95vh, 367px);
  transform: translate3d(98vw, 95vh, 367px);
  background: #5926d9;
}
.bubble:nth-child(17) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -3.4s;
  -moz-animation-delay: -3.4s;
  -o-animation-delay: -3.4s;
  animation-delay: -3.4s;
  -webkit-transform: translate3d(5vw, 88vh, 544px);
  -moz-transform: translate3d(5vw, 88vh, 544px);
  -ms-transform: translate3d(5vw, 88vh, 544px);
  -o-transform: translate3d(5vw, 88vh, 544px);
  transform: translate3d(5vw, 88vh, 544px);
  background: #b826d9;
}
.bubble:nth-child(18) {
  height: 14px;
  width: 14px;
  margin-left: -14px/2;
  margin-top: -14px/2;
  -webkit-animation-delay: -3.6s;
  -moz-animation-delay: -3.6s;
  -o-animation-delay: -3.6s;
  animation-delay: -3.6s;
  -webkit-transform: translate3d(47vw, 12vh, 23px);
  -moz-transform: translate3d(47vw, 12vh, 23px);
  -ms-transform: translate3d(47vw, 12vh, 23px);
  -o-transform: translate3d(47vw, 12vh, 23px);
  transform: translate3d(47vw, 12vh, 23px);
  background: #d95326;
}
.bubble:nth-child(19) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -3.8s;
  -moz-animation-delay: -3.8s;
  -o-animation-delay: -3.8s;
  animation-delay: -3.8s;
  -webkit-transform: translate3d(7vw, 2vh, 572px);
  -moz-transform: translate3d(7vw, 2vh, 572px);
  -ms-transform: translate3d(7vw, 2vh, 572px);
  -o-transform: translate3d(7vw, 2vh, 572px);
  transform: translate3d(7vw, 2vh, 572px);
  background: #2685d9;
}
.bubble:nth-child(20) {
  height: 28px;
  width: 28px;
  margin-left: -28px/2;
  margin-top: -28px/2;
  -webkit-animation-delay: -4s;
  -moz-animation-delay: -4s;
  -o-animation-delay: -4s;
  animation-delay: -4s;
  -webkit-transform: translate3d(80vw, 96vh, 85px);
  -moz-transform: translate3d(80vw, 96vh, 85px);
  -ms-transform: translate3d(80vw, 96vh, 85px);
  -o-transform: translate3d(80vw, 96vh, 85px);
  transform: translate3d(80vw, 96vh, 85px);
  background: #2635d9;
}
.bubble:nth-child(21) {
  height: 24px;
  width: 24px;
  margin-left: -24px/2;
  margin-top: -24px/2;
  -webkit-animation-delay: -4.2s;
  -moz-animation-delay: -4.2s;
  -o-animation-delay: -4.2s;
  animation-delay: -4.2s;
  -webkit-transform: translate3d(68vw, 13vh, 463px);
  -moz-transform: translate3d(68vw, 13vh, 463px);
  -ms-transform: translate3d(68vw, 13vh, 463px);
  -o-transform: translate3d(68vw, 13vh, 463px);
  transform: translate3d(68vw, 13vh, 463px);
  background: #266ed9;
}
.bubble:nth-child(22) {
  height: 10px;
  width: 10px;
  margin-left: -10px/2;
  margin-top: -10px/2;
  -webkit-animation-delay: -4.4s;
  -moz-animation-delay: -4.4s;
  -o-animation-delay: -4.4s;
  animation-delay: -4.4s;
  -webkit-transform: translate3d(70vw, 68vh, 90px);
  -moz-transform: translate3d(70vw, 68vh, 90px);
  -ms-transform: translate3d(70vw, 68vh, 90px);
  -o-transform: translate3d(70vw, 68vh, 90px);
  transform: translate3d(70vw, 68vh, 90px);
  background: #5026d9;
}
.bubble:nth-child(23) {
  height: 15px;
  width: 15px;
  margin-left: -15px/2;
  margin-top: -15px/2;
  -webkit-animation-delay: -4.6s;
  -moz-animation-delay: -4.6s;
  -o-animation-delay: -4.6s;
  animation-delay: -4.6s;
  -webkit-transform: translate3d(32vw, 36vh, 314px);
  -moz-transform: translate3d(32vw, 36vh, 314px);
  -ms-transform: translate3d(32vw, 36vh, 314px);
  -o-transform: translate3d(32vw, 36vh, 314px);
  transform: translate3d(32vw, 36vh, 314px);
  background: #26d9bb;
}
.bubble:nth-child(24) {
  height: 11px;
  width: 11px;
  margin-left: -11px/2;
  margin-top: -11px/2;
  -webkit-animation-delay: -4.8s;
  -moz-animation-delay: -4.8s;
  -o-animation-delay: -4.8s;
  animation-delay: -4.8s;
  -webkit-transform: translate3d(56vw, 42vh, 101px);
  -moz-transform: translate3d(56vw, 42vh, 101px);
  -ms-transform: translate3d(56vw, 42vh, 101px);
  -o-transform: translate3d(56vw, 42vh, 101px);
  transform: translate3d(56vw, 42vh, 101px);
  background: #d92653;
}
.bubble:nth-child(25) {
  height: 24px;
  width: 24px;
  margin-left: -24px/2;
  margin-top: -24px/2;
  -webkit-animation-delay: -5s;
  -moz-animation-delay: -5s;
  -o-animation-delay: -5s;
  animation-delay: -5s;
  -webkit-transform: translate3d(45vw, 88vh, 275px);
  -moz-transform: translate3d(45vw, 88vh, 275px);
  -ms-transform: translate3d(45vw, 88vh, 275px);
  -o-transform: translate3d(45vw, 88vh, 275px);
  transform: translate3d(45vw, 88vh, 275px);
  background: #afd926;
}
.bubble:nth-child(26) {
  height: 13px;
  width: 13px;
  margin-left: -13px/2;
  margin-top: -13px/2;
  -webkit-animation-delay: -5.2s;
  -moz-animation-delay: -5.2s;
  -o-animation-delay: -5.2s;
  animation-delay: -5.2s;
  -webkit-transform: translate3d(73vw, 5vh, 321px);
  -moz-transform: translate3d(73vw, 5vh, 321px);
  -ms-transform: translate3d(73vw, 5vh, 321px);
  -o-transform: translate3d(73vw, 5vh, 321px);
  transform: translate3d(73vw, 5vh, 321px);
  background: #26c4d9;
}
.bubble:nth-child(27) {
  height: 2px;
  width: 2px;
  margin-left: -2px/2;
  margin-top: -2px/2;
  -webkit-animation-delay: -5.4s;
  -moz-animation-delay: -5.4s;
  -o-animation-delay: -5.4s;
  animation-delay: -5.4s;
  -webkit-transform: translate3d(44vw, 42vh, 551px);
  -moz-transform: translate3d(44vw, 42vh, 551px);
  -ms-transform: translate3d(44vw, 42vh, 551px);
  -o-transform: translate3d(44vw, 42vh, 551px);
  transform: translate3d(44vw, 42vh, 551px);
  background: #53d926;
}
.bubble:nth-child(28) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -5.6s;
  -moz-animation-delay: -5.6s;
  -o-animation-delay: -5.6s;
  animation-delay: -5.6s;
  -webkit-transform: translate3d(63vw, 34vh, 479px);
  -moz-transform: translate3d(63vw, 34vh, 479px);
  -ms-transform: translate3d(63vw, 34vh, 479px);
  -o-transform: translate3d(63vw, 34vh, 479px);
  transform: translate3d(63vw, 34vh, 479px);
  background: #d9268e;
}
.bubble:nth-child(29) {
  height: 30px;
  width: 30px;
  margin-left: -30px/2;
  margin-top: -30px/2;
  -webkit-animation-delay: -5.8s;
  -moz-animation-delay: -5.8s;
  -o-animation-delay: -5.8s;
  animation-delay: -5.8s;
  -webkit-transform: translate3d(100vw, 35vh, 443px);
  -moz-transform: translate3d(100vw, 35vh, 443px);
  -ms-transform: translate3d(100vw, 35vh, 443px);
  -o-transform: translate3d(100vw, 35vh, 443px);
  transform: translate3d(100vw, 35vh, 443px);
  background: #88d926;
}
.bubble:nth-child(30) {
  height: 4px;
  width: 4px;
  margin-left: -4px/2;
  margin-top: -4px/2;
  -webkit-animation-delay: -6s;
  -moz-animation-delay: -6s;
  -o-animation-delay: -6s;
  animation-delay: -6s;
  -webkit-transform: translate3d(35vw, 69vh, 18px);
  -moz-transform: translate3d(35vw, 69vh, 18px);
  -ms-transform: translate3d(35vw, 69vh, 18px);
  -o-transform: translate3d(35vw, 69vh, 18px);
  transform: translate3d(35vw, 69vh, 18px);
  background: #af26d9;
}
.bubble:nth-child(31) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -6.2s;
  -moz-animation-delay: -6.2s;
  -o-animation-delay: -6.2s;
  animation-delay: -6.2s;
  -webkit-transform: translate3d(39vw, 50vh, 426px);
  -moz-transform: translate3d(39vw, 50vh, 426px);
  -ms-transform: translate3d(39vw, 50vh, 426px);
  -o-transform: translate3d(39vw, 50vh, 426px);
  transform: translate3d(39vw, 50vh, 426px);
  background: #d9c426;
}
.bubble:nth-child(32) {
  height: 15px;
  width: 15px;
  margin-left: -15px/2;
  margin-top: -15px/2;
  -webkit-animation-delay: -6.4s;
  -moz-animation-delay: -6.4s;
  -o-animation-delay: -6.4s;
  animation-delay: -6.4s;
  -webkit-transform: translate3d(39vw, 52vh, 1px);
  -moz-transform: translate3d(39vw, 52vh, 1px);
  -ms-transform: translate3d(39vw, 52vh, 1px);
  -o-transform: translate3d(39vw, 52vh, 1px);
  transform: translate3d(39vw, 52vh, 1px);
  background: #265fd9;
}
.bubble:nth-child(33) {
  height: 15px;
  width: 15px;
  margin-left: -15px/2;
  margin-top: -15px/2;
  -webkit-animation-delay: -6.6s;
  -moz-animation-delay: -6.6s;
  -o-animation-delay: -6.6s;
  animation-delay: -6.6s;
  -webkit-transform: translate3d(98vw, 99vh, 213px);
  -moz-transform: translate3d(98vw, 99vh, 213px);
  -ms-transform: translate3d(98vw, 99vh, 213px);
  -o-transform: translate3d(98vw, 99vh, 213px);
  transform: translate3d(98vw, 99vh, 213px);
  background: #26d971;
}
.bubble:nth-child(34) {
  height: 26px;
  width: 26px;
  margin-left: -26px/2;
  margin-top: -26px/2;
  -webkit-animation-delay: -6.8s;
  -moz-animation-delay: -6.8s;
  -o-animation-delay: -6.8s;
  animation-delay: -6.8s;
  -webkit-transform: translate3d(67vw, 43vh, 172px);
  -moz-transform: translate3d(67vw, 43vh, 172px);
  -ms-transform: translate3d(67vw, 43vh, 172px);
  -o-transform: translate3d(67vw, 43vh, 172px);
  transform: translate3d(67vw, 43vh, 172px);
  background: #d9d626;
}
.bubble:nth-child(35) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -7s;
  -moz-animation-delay: -7s;
  -o-animation-delay: -7s;
  animation-delay: -7s;
  -webkit-transform: translate3d(49vw, 88vh, 492px);
  -moz-transform: translate3d(49vw, 88vh, 492px);
  -ms-transform: translate3d(49vw, 88vh, 492px);
  -o-transform: translate3d(49vw, 88vh, 492px);
  transform: translate3d(49vw, 88vh, 492px);
  background: #d926c1;
}
.bubble:nth-child(36) {
  height: 3px;
  width: 3px;
  margin-left: -3px/2;
  margin-top: -3px/2;
  -webkit-animation-delay: -7.2s;
  -moz-animation-delay: -7.2s;
  -o-animation-delay: -7.2s;
  animation-delay: -7.2s;
  -webkit-transform: translate3d(97vw, 56vh, 63px);
  -moz-transform: translate3d(97vw, 56vh, 63px);
  -ms-transform: translate3d(97vw, 56vh, 63px);
  -o-transform: translate3d(97vw, 56vh, 63px);
  transform: translate3d(97vw, 56vh, 63px);
  background: #77d926;
}
.bubble:nth-child(37) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -7.4s;
  -moz-animation-delay: -7.4s;
  -o-animation-delay: -7.4s;
  animation-delay: -7.4s;
  -webkit-transform: translate3d(22vw, 33vh, 513px);
  -moz-transform: translate3d(22vw, 33vh, 513px);
  -ms-transform: translate3d(22vw, 33vh, 513px);
  -o-transform: translate3d(22vw, 33vh, 513px);
  transform: translate3d(22vw, 33vh, 513px);
  background: #d92665;
}
.bubble:nth-child(38) {
  height: 11px;
  width: 11px;
  margin-left: -11px/2;
  margin-top: -11px/2;
  -webkit-animation-delay: -7.6s;
  -moz-animation-delay: -7.6s;
  -o-animation-delay: -7.6s;
  animation-delay: -7.6s;
  -webkit-transform: translate3d(23vw, 96vh, 340px);
  -moz-transform: translate3d(23vw, 96vh, 340px);
  -ms-transform: translate3d(23vw, 96vh, 340px);
  -o-transform: translate3d(23vw, 96vh, 340px);
  transform: translate3d(23vw, 96vh, 340px);
  background: #3b26d9;
}
.bubble:nth-child(39) {
  height: 25px;
  width: 25px;
  margin-left: -25px/2;
  margin-top: -25px/2;
  -webkit-animation-delay: -7.8s;
  -moz-animation-delay: -7.8s;
  -o-animation-delay: -7.8s;
  animation-delay: -7.8s;
  -webkit-transform: translate3d(27vw, 81vh, 295px);
  -moz-transform: translate3d(27vw, 81vh, 295px);
  -ms-transform: translate3d(27vw, 81vh, 295px);
  -o-transform: translate3d(27vw, 81vh, 295px);
  transform: translate3d(27vw, 81vh, 295px);
  background: #26d982;
}
.bubble:nth-child(40) {
  height: 6px;
  width: 6px;
  margin-left: -6px/2;
  margin-top: -6px/2;
  -webkit-animation-delay: -8s;
  -moz-animation-delay: -8s;
  -o-animation-delay: -8s;
  animation-delay: -8s;
  -webkit-transform: translate3d(70vw, 11vh, 213px);
  -moz-transform: translate3d(70vw, 11vh, 213px);
  -ms-transform: translate3d(70vw, 11vh, 213px);
  -o-transform: translate3d(70vw, 11vh, 213px);
  transform: translate3d(70vw, 11vh, 213px);
  background: #4dd926;
}
.bubble:nth-child(41) {
  height: 15px;
  width: 15px;
  margin-left: -15px/2;
  margin-top: -15px/2;
  -webkit-animation-delay: -8.2s;
  -moz-animation-delay: -8.2s;
  -o-animation-delay: -8.2s;
  animation-delay: -8.2s;
  -webkit-transform: translate3d(36vw, 96vh, 280px);
  -moz-transform: translate3d(36vw, 96vh, 280px);
  -ms-transform: translate3d(36vw, 96vh, 280px);
  -o-transform: translate3d(36vw, 96vh, 280px);
  transform: translate3d(36vw, 96vh, 280px);
  background: #d94726;
}
.bubble:nth-child(42) {
  height: 17px;
  width: 17px;
  margin-left: -17px/2;
  margin-top: -17px/2;
  -webkit-animation-delay: -8.4s;
  -moz-animation-delay: -8.4s;
  -o-animation-delay: -8.4s;
  animation-delay: -8.4s;
  -webkit-transform: translate3d(65vw, 56vh, 327px);
  -moz-transform: translate3d(65vw, 56vh, 327px);
  -ms-transform: translate3d(65vw, 56vh, 327px);
  -o-transform: translate3d(65vw, 56vh, 327px);
  transform: translate3d(65vw, 56vh, 327px);
  background: #a026d9;
}
.bubble:nth-child(43) {
  height: 8px;
  width: 8px;
  margin-left: -8px/2;
  margin-top: -8px/2;
  -webkit-animation-delay: -8.6s;
  -moz-animation-delay: -8.6s;
  -o-animation-delay: -8.6s;
  animation-delay: -8.6s;
  -webkit-transform: translate3d(39vw, 66vh, 449px);
  -moz-transform: translate3d(39vw, 66vh, 449px);
  -ms-transform: translate3d(39vw, 66vh, 449px);
  -o-transform: translate3d(39vw, 66vh, 449px);
  transform: translate3d(39vw, 66vh, 449px);
  background: #d94426;
}
.bubble:nth-child(44) {
  height: 3px;
  width: 3px;
  margin-left: -3px/2;
  margin-top: -3px/2;
  -webkit-animation-delay: -8.8s;
  -moz-animation-delay: -8.8s;
  -o-animation-delay: -8.8s;
  animation-delay: -8.8s;
  -webkit-transform: translate3d(48vw, 84vh, 544px);
  -moz-transform: translate3d(48vw, 84vh, 544px);
  -ms-transform: translate3d(48vw, 84vh, 544px);
  -o-transform: translate3d(48vw, 84vh, 544px);
  transform: translate3d(48vw, 84vh, 544px);
  background: #2650d9;
}
.bubble:nth-child(45) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -9s;
  -moz-animation-delay: -9s;
  -o-animation-delay: -9s;
  animation-delay: -9s;
  -webkit-transform: translate3d(55vw, 68vh, 149px);
  -moz-transform: translate3d(55vw, 68vh, 149px);
  -ms-transform: translate3d(55vw, 68vh, 149px);
  -o-transform: translate3d(55vw, 68vh, 149px);
  transform: translate3d(55vw, 68vh, 149px);
  background: #a0d926;
}
.bubble:nth-child(46) {
  height: 11px;
  width: 11px;
  margin-left: -11px/2;
  margin-top: -11px/2;
  -webkit-animation-delay: -9.2s;
  -moz-animation-delay: -9.2s;
  -o-animation-delay: -9.2s;
  animation-delay: -9.2s;
  -webkit-transform: translate3d(40vw, 18vh, 141px);
  -moz-transform: translate3d(40vw, 18vh, 141px);
  -ms-transform: translate3d(40vw, 18vh, 141px);
  -o-transform: translate3d(40vw, 18vh, 141px);
  transform: translate3d(40vw, 18vh, 141px);
  background: #26acd9;
}
.bubble:nth-child(47) {
  height: 21px;
  width: 21px;
  margin-left: -21px/2;
  margin-top: -21px/2;
  -webkit-animation-delay: -9.4s;
  -moz-animation-delay: -9.4s;
  -o-animation-delay: -9.4s;
  animation-delay: -9.4s;
  -webkit-transform: translate3d(40vw, 81vh, 319px);
  -moz-transform: translate3d(40vw, 81vh, 319px);
  -ms-transform: translate3d(40vw, 81vh, 319px);
  -o-transform: translate3d(40vw, 81vh, 319px);
  transform: translate3d(40vw, 81vh, 319px);
  background: #4a26d9;
}
.bubble:nth-child(48) {
  height: 22px;
  width: 22px;
  margin-left: -22px/2;
  margin-top: -22px/2;
  -webkit-animation-delay: -9.6s;
  -moz-animation-delay: -9.6s;
  -o-animation-delay: -9.6s;
  animation-delay: -9.6s;
  -webkit-transform: translate3d(23vw, 32vh, 572px);
  -moz-transform: translate3d(23vw, 32vh, 572px);
  -ms-transform: translate3d(23vw, 32vh, 572px);
  -o-transform: translate3d(23vw, 32vh, 572px);
  transform: translate3d(23vw, 32vh, 572px);
  background: #8026d9;
}
.bubble:nth-child(49) {
  height: 7px;
  width: 7px;
  margin-left: -7px/2;
  margin-top: -7px/2;
  -webkit-animation-delay: -9.8s;
  -moz-animation-delay: -9.8s;
  -o-animation-delay: -9.8s;
  animation-delay: -9.8s;
  -webkit-transform: translate3d(31vw, 95vh, 291px);
  -moz-transform: translate3d(31vw, 95vh, 291px);
  -ms-transform: translate3d(31vw, 95vh, 291px);
  -o-transform: translate3d(31vw, 95vh, 291px);
  transform: translate3d(31vw, 95vh, 291px);
  background: #2c26d9;
}
.bubble:nth-child(50) {
  height: 30px;
  width: 30px;
  margin-left: -30px/2;
  margin-top: -30px/2;
  -webkit-animation-delay: -10s;
  -moz-animation-delay: -10s;
  -o-animation-delay: -10s;
  animation-delay: -10s;
  -webkit-transform: translate3d(71vw, 13vh, 206px);
  -moz-transform: translate3d(71vw, 13vh, 206px);
  -ms-transform: translate3d(71vw, 13vh, 206px);
  -o-transform: translate3d(71vw, 13vh, 206px);
  transform: translate3d(71vw, 13vh, 206px);
  background: #26d0d9;
}
.bubble:nth-child(51) {
  height: 6px;
  width: 6px;
  margin-left: -6px/2;
  margin-top: -6px/2;
  -webkit-animation-delay: -10.2s;
  -moz-animation-delay: -10.2s;
  -o-animation-delay: -10.2s;
  animation-delay: -10.2s;
  -webkit-transform: translate3d(22vw, 56vh, 92px);
  -moz-transform: translate3d(22vw, 56vh, 92px);
  -ms-transform: translate3d(22vw, 56vh, 92px);
  -o-transform: translate3d(22vw, 56vh, 92px);
  transform: translate3d(22vw, 56vh, 92px);
  background: #26d9d0;
}
.bubble:nth-child(52) {
  height: 19px;
  width: 19px;
  margin-left: -19px/2;
  margin-top: -19px/2;
  -webkit-animation-delay: -10.4s;
  -moz-animation-delay: -10.4s;
  -o-animation-delay: -10.4s;
  animation-delay: -10.4s;
  -webkit-transform: translate3d(41vw, 85vh, 277px);
  -moz-transform: translate3d(41vw, 85vh, 277px);
  -ms-transform: translate3d(41vw, 85vh, 277px);
  -o-transform: translate3d(41vw, 85vh, 277px);
  transform: translate3d(41vw, 85vh, 277px);
  background: #d326d9;
}
.bubble:nth-child(53) {
  height: 24px;
  width: 24px;
  margin-left: -24px/2;
  margin-top: -24px/2;
  -webkit-animation-delay: -10.6s;
  -moz-animation-delay: -10.6s;
  -o-animation-delay: -10.6s;
  animation-delay: -10.6s;
  -webkit-transform: translate3d(77vw, 13vh, 383px);
  -moz-transform: translate3d(77vw, 13vh, 383px);
  -ms-transform: translate3d(77vw, 13vh, 383px);
  -o-transform: translate3d(77vw, 13vh, 383px);
  transform: translate3d(77vw, 13vh, 383px);
  background: #d9a326;
}
.bubble:nth-child(54) {
  height: 25px;
  width: 25px;
  margin-left: -25px/2;
  margin-top: -25px/2;
  -webkit-animation-delay: -10.8s;
  -moz-animation-delay: -10.8s;
  -o-animation-delay: -10.8s;
  animation-delay: -10.8s;
  -webkit-transform: translate3d(91vw, 45vh, 124px);
  -moz-transform: translate3d(91vw, 45vh, 124px);
  -ms-transform: translate3d(91vw, 45vh, 124px);
  -o-transform: translate3d(91vw, 45vh, 124px);
  transform: translate3d(91vw, 45vh, 124px);
  background: #4dd926;
}
.bubble:nth-child(55) {
  height: 1px;
  width: 1px;
  margin-left: -1px/2;
  margin-top: -1px/2;
  -webkit-animation-delay: -11s;
  -moz-animation-delay: -11s;
  -o-animation-delay: -11s;
  animation-delay: -11s;
  -webkit-transform: translate3d(31vw, 63vh, 204px);
  -moz-transform: translate3d(31vw, 63vh, 204px);
  -ms-transform: translate3d(31vw, 63vh, 204px);
  -o-transform: translate3d(31vw, 63vh, 204px);
  transform: translate3d(31vw, 63vh, 204px);
  background: #4ad926;
}
.bubble:nth-child(56) {
  height: 18px;
  width: 18px;
  margin-left: -18px/2;
  margin-top: -18px/2;
  -webkit-animation-delay: -11.2s;
  -moz-animation-delay: -11.2s;
  -o-animation-delay: -11.2s;
  animation-delay: -11.2s;
  -webkit-transform: translate3d(12vw, 21vh, 215px);
  -moz-transform: translate3d(12vw, 21vh, 215px);
  -ms-transform: translate3d(12vw, 21vh, 215px);
  -o-transform: translate3d(12vw, 21vh, 215px);
  transform: translate3d(12vw, 21vh, 215px);
  background: #d92635;
}
.bubble:nth-child(57) {
  height: 11px;
  width: 11px;
  margin-left: -11px/2;
  margin-top: -11px/2;
  -webkit-animation-delay: -11.4s;
  -moz-animation-delay: -11.4s;
  -o-animation-delay: -11.4s;
  animation-delay: -11.4s;
  -webkit-transform: translate3d(42vw, 71vh, 50px);
  -moz-transform: translate3d(42vw, 71vh, 50px);
  -ms-transform: translate3d(42vw, 71vh, 50px);
  -o-transform: translate3d(42vw, 71vh, 50px);
  transform: translate3d(42vw, 71vh, 50px);
  background: #5f26d9;
}
.bubble:nth-child(58) {
  height: 18px;
  width: 18px;
  margin-left: -18px/2;
  margin-top: -18px/2;
  -webkit-animation-delay: -11.6s;
  -moz-animation-delay: -11.6s;
  -o-animation-delay: -11.6s;
  animation-delay: -11.6s;
  -webkit-transform: translate3d(79vw, 8vh, 366px);
  -moz-transform: translate3d(79vw, 8vh, 366px);
  -ms-transform: translate3d(79vw, 8vh, 366px);
  -o-transform: translate3d(79vw, 8vh, 366px);
  transform: translate3d(79vw, 8vh, 366px);
  background: #2653d9;
}
.bubble:nth-child(59) {
  height: 30px;
  width: 30px;
  margin-left: -30px/2;
  margin-top: -30px/2;
  -webkit-animation-delay: -11.8s;
  -moz-animation-delay: -11.8s;
  -o-animation-delay: -11.8s;
  animation-delay: -11.8s;
  -webkit-transform: translate3d(31vw, 81vh, 12px);
  -moz-transform: translate3d(31vw, 81vh, 12px);
  -ms-transform: translate3d(31vw, 81vh, 12px);
  -o-transform: translate3d(31vw, 81vh, 12px);
  transform: translate3d(31vw, 81vh, 12px);
  background: #4126d9;
}
.bubble:nth-child(60) {
  height: 21px;
  width: 21px;
  margin-left: -21px/2;
  margin-top: -21px/2;
  -webkit-animation-delay: -12s;
  -moz-animation-delay: -12s;
  -o-animation-delay: -12s;
  animation-delay: -12s;
  -webkit-transform: translate3d(93vw, 86vh, 114px);
  -moz-transform: translate3d(93vw, 86vh, 114px);
  -ms-transform: translate3d(93vw, 86vh, 114px);
  -o-transform: translate3d(93vw, 86vh, 114px);
  transform: translate3d(93vw, 86vh, 114px);
  background: #2f26d9;
}
.bubble:nth-child(61) {
  height: 19px;
  width: 19px;
  margin-left: -19px/2;
  margin-top: -19px/2;
  -webkit-animation-delay: -12.2s;
  -moz-animation-delay: -12.2s;
  -o-animation-delay: -12.2s;
  animation-delay: -12.2s;
  -webkit-transform: translate3d(86vw, 96vh, 252px);
  -moz-transform: translate3d(86vw, 96vh, 252px);
  -ms-transform: translate3d(86vw, 96vh, 252px);
  -o-transform: translate3d(86vw, 96vh, 252px);
  transform: translate3d(86vw, 96vh, 252px);
  background: #2680d9;
}
.bubble:nth-child(62) {
  height: 13px;
  width: 13px;
  margin-left: -13px/2;
  margin-top: -13px/2;
  -webkit-animation-delay: -12.4s;
  -moz-animation-delay: -12.4s;
  -o-animation-delay: -12.4s;
  animation-delay: -12.4s;
  -webkit-transform: translate3d(10vw, 82vh, 265px);
  -moz-transform: translate3d(10vw, 82vh, 265px);
  -ms-transform: translate3d(10vw, 82vh, 265px);
  -o-transform: translate3d(10vw, 82vh, 265px);
  transform: translate3d(10vw, 82vh, 265px);
  background: #2671d9;
}
.bubble:nth-child(63) {
  height: 29px;
  width: 29px;
  margin-left: -29px/2;
  margin-top: -29px/2;
  -webkit-animation-delay: -12.6s;
  -moz-animation-delay: -12.6s;
  -o-animation-delay: -12.6s;
  animation-delay: -12.6s;
  -webkit-transform: translate3d(85vw, 21vh, 99px);
  -moz-transform: translate3d(85vw, 21vh, 99px);
  -ms-transform: translate3d(85vw, 21vh, 99px);
  -o-transform: translate3d(85vw, 21vh, 99px);
  transform: translate3d(85vw, 21vh, 99px);
  background: #26d94d;
}
.bubble:nth-child(64) {
  height: 10px;
  width: 10px;
  margin-left: -10px/2;
  margin-top: -10px/2;
  -webkit-animation-delay: -12.8s;
  -moz-animation-delay: -12.8s;
  -o-animation-delay: -12.8s;
  animation-delay: -12.8s;
  -webkit-transform: translate3d(30vw, 78vh, 101px);
  -moz-transform: translate3d(30vw, 78vh, 101px);
  -ms-transform: translate3d(30vw, 78vh, 101px);
  -o-transform: translate3d(30vw, 78vh, 101px);
  transform: translate3d(30vw, 78vh, 101px);
  background: #cd26d9;
}
.bubble:nth-child(65) {
  height: 25px;
  width: 25px;
  margin-left: -25px/2;
  margin-top: -25px/2;
  -webkit-animation-delay: -13s;
  -moz-animation-delay: -13s;
  -o-animation-delay: -13s;
  animation-delay: -13s;
  -webkit-transform: translate3d(3vw, 5vh, 446px);
  -moz-transform: translate3d(3vw, 5vh, 446px);
  -ms-transform: translate3d(3vw, 5vh, 446px);
  -o-transform: translate3d(3vw, 5vh, 446px);
  transform: translate3d(3vw, 5vh, 446px);
  background: #d926d3;
}
.bubble:nth-child(66) {
  height: 3px;
  width: 3px;
  margin-left: -3px/2;
  margin-top: -3px/2;
  -webkit-animation-delay: -13.2s;
  -moz-animation-delay: -13.2s;
  -o-animation-delay: -13.2s;
  animation-delay: -13.2s;
  -webkit-transform: translate3d(29vw, 90vh, 484px);
  -moz-transform: translate3d(29vw, 90vh, 484px);
  -ms-transform: translate3d(29vw, 90vh, 484px);
  -o-transform: translate3d(29vw, 90vh, 484px);
  transform: translate3d(29vw, 90vh, 484px);
  background: #d92653;
}
.bubble:nth-child(67) {
  height: 25px;
  width: 25px;
  margin-left: -25px/2;
  margin-top: -25px/2;
  -webkit-animation-delay: -13.4s;
  -moz-animation-delay: -13.4s;
  -o-animation-delay: -13.4s;
  animation-delay: -13.4s;
  -webkit-transform: translate3d(70vw, 11vh, 336px);
  -moz-transform: translate3d(70vw, 11vh, 336px);
  -ms-transform: translate3d(70vw, 11vh, 336px);
  -o-transform: translate3d(70vw, 11vh, 336px);
  transform: translate3d(70vw, 11vh, 336px);
  background: #2647d9;
}
.bubble:nth-child(68) {
  height: 2px;
  width: 2px;
  margin-left: -2px/2;
  margin-top: -2px/2;
  -webkit-animation-delay: -13.6s;
  -moz-animation-delay: -13.6s;
  -o-animation-delay: -13.6s;
  animation-delay: -13.6s;
  -webkit-transform: translate3d(77vw, 94vh, 225px);
  -moz-transform: translate3d(77vw, 94vh, 225px);
  -ms-transform: translate3d(77vw, 94vh, 225px);
  -o-transform: translate3d(77vw, 94vh, 225px);
  transform: translate3d(77vw, 94vh, 225px);
  background: #d92635;
}
.bubble:nth-child(69) {
  height: 16px;
  width: 16px;
  margin-left: -16px/2;
  margin-top: -16px/2;
  -webkit-animation-delay: -13.8s;
  -moz-animation-delay: -13.8s;
  -o-animation-delay: -13.8s;
  animation-delay: -13.8s;
  -webkit-transform: translate3d(11vw, 5vh, 216px);
  -moz-transform: translate3d(11vw, 5vh, 216px);
  -ms-transform: translate3d(11vw, 5vh, 216px);
  -o-transform: translate3d(11vw, 5vh, 216px);
  transform: translate3d(11vw, 5vh, 216px);
  background: #267dd9;
}
.bubble:nth-child(70) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -14s;
  -moz-animation-delay: -14s;
  -o-animation-delay: -14s;
  animation-delay: -14s;
  -webkit-transform: translate3d(77vw, 18vh, 215px);
  -moz-transform: translate3d(77vw, 18vh, 215px);
  -ms-transform: translate3d(77vw, 18vh, 215px);
  -o-transform: translate3d(77vw, 18vh, 215px);
  transform: translate3d(77vw, 18vh, 215px);
  background: #d95f26;
}
.bubble:nth-child(71) {
  height: 14px;
  width: 14px;
  margin-left: -14px/2;
  margin-top: -14px/2;
  -webkit-animation-delay: -14.2s;
  -moz-animation-delay: -14.2s;
  -o-animation-delay: -14.2s;
  animation-delay: -14.2s;
  -webkit-transform: translate3d(7vw, 3vh, 207px);
  -moz-transform: translate3d(7vw, 3vh, 207px);
  -ms-transform: translate3d(7vw, 3vh, 207px);
  -o-transform: translate3d(7vw, 3vh, 207px);
  transform: translate3d(7vw, 3vh, 207px);
  background: #a626d9;
}
.bubble:nth-child(72) {
  height: 23px;
  width: 23px;
  margin-left: -23px/2;
  margin-top: -23px/2;
  -webkit-animation-delay: -14.4s;
  -moz-animation-delay: -14.4s;
  -o-animation-delay: -14.4s;
  animation-delay: -14.4s;
  -webkit-transform: translate3d(4vw, 12vh, 514px);
  -moz-transform: translate3d(4vw, 12vh, 514px);
  -ms-transform: translate3d(4vw, 12vh, 514px);
  -o-transform: translate3d(4vw, 12vh, 514px);
  transform: translate3d(4vw, 12vh, 514px);
  background: #9426d9;
}
.bubble:nth-child(73) {
  height: 15px;
  width: 15px;
  margin-left: -15px/2;
  margin-top: -15px/2;
  -webkit-animation-delay: -14.6s;
  -moz-animation-delay: -14.6s;
  -o-animation-delay: -14.6s;
  animation-delay: -14.6s;
  -webkit-transform: translate3d(10vw, 73vh, 171px);
  -moz-transform: translate3d(10vw, 73vh, 171px);
  -ms-transform: translate3d(10vw, 73vh, 171px);
  -o-transform: translate3d(10vw, 73vh, 171px);
  transform: translate3d(10vw, 73vh, 171px);
  background: #d9a926;
}
.bubble:nth-child(74) {
  height: 4px;
  width: 4px;
  margin-left: -4px/2;
  margin-top: -4px/2;
  -webkit-animation-delay: -14.8s;
  -moz-animation-delay: -14.8s;
  -o-animation-delay: -14.8s;
  animation-delay: -14.8s;
  -webkit-transform: translate3d(46vw, 68vh, 587px);
  -moz-transform: translate3d(46vw, 68vh, 587px);
  -ms-transform: translate3d(46vw, 68vh, 587px);
  -o-transform: translate3d(46vw, 68vh, 587px);
  transform: translate3d(46vw, 68vh, 587px);
  background: #d96526;
}
.bubble:nth-child(75) {
  height: 8px;
  width: 8px;
  margin-left: -8px/2;
  margin-top: -8px/2;
  -webkit-animation-delay: -15s;
  -moz-animation-delay: -15s;
  -o-animation-delay: -15s;
  animation-delay: -15s;
  -webkit-transform: translate3d(40vw, 89vh, 158px);
  -moz-transform: translate3d(40vw, 89vh, 158px);
  -ms-transform: translate3d(40vw, 89vh, 158px);
  -o-transform: translate3d(40vw, 89vh, 158px);
  transform: translate3d(40vw, 89vh, 158px);
  background: #4ad926;
}
.bubble:nth-child(76) {
  height: 25px;
  width: 25px;
  margin-left: -25px/2;
  margin-top: -25px/2;
  -webkit-animation-delay: -15.2s;
  -moz-animation-delay: -15.2s;
  -o-animation-delay: -15.2s;
  animation-delay: -15.2s;
  -webkit-transform: translate3d(49vw, 50vh, 252px);
  -moz-transform: translate3d(49vw, 50vh, 252px);
  -ms-transform: translate3d(49vw, 50vh, 252px);
  -o-transform: translate3d(49vw, 50vh, 252px);
  transform: translate3d(49vw, 50vh, 252px);
  background: #26d99a;
}
.bubble:nth-child(77) {
  height: 2px;
  width: 2px;
  margin-left: -2px/2;
  margin-top: -2px/2;
  -webkit-animation-delay: -15.4s;
  -moz-animation-delay: -15.4s;
  -o-animation-delay: -15.4s;
  animation-delay: -15.4s;
  -webkit-transform: translate3d(82vw, 29vh, 241px);
  -moz-transform: translate3d(82vw, 29vh, 241px);
  -ms-transform: translate3d(82vw, 29vh, 241px);
  -o-transform: translate3d(82vw, 29vh, 241px);
  transform: translate3d(82vw, 29vh, 241px);
  background: #2665d9;
}
.bubble:nth-child(78) {
  height: 6px;
  width: 6px;
  margin-left: -6px/2;
  margin-top: -6px/2;
  -webkit-animation-delay: -15.6s;
  -moz-animation-delay: -15.6s;
  -o-animation-delay: -15.6s;
  animation-delay: -15.6s;
  -webkit-transform: translate3d(54vw, 25vh, 576px);
  -moz-transform: translate3d(54vw, 25vh, 576px);
  -ms-transform: translate3d(54vw, 25vh, 576px);
  -o-transform: translate3d(54vw, 25vh, 576px);
  transform: translate3d(54vw, 25vh, 576px);
  background: #d99426;
}
.bubble:nth-child(79) {
  height: 18px;
  width: 18px;
  margin-left: -18px/2;
  margin-top: -18px/2;
  -webkit-animation-delay: -15.8s;
  -moz-animation-delay: -15.8s;
  -o-animation-delay: -15.8s;
  animation-delay: -15.8s;
  -webkit-transform: translate3d(65vw, 51vh, 432px);
  -moz-transform: translate3d(65vw, 51vh, 432px);
  -ms-transform: translate3d(65vw, 51vh, 432px);
  -o-transform: translate3d(65vw, 51vh, 432px);
  transform: translate3d(65vw, 51vh, 432px);
  background: #26d95f;
}
.bubble:nth-child(80) {
  height: 27px;
  width: 27px;
  margin-left: -27px/2;
  margin-top: -27px/2;
  -webkit-animation-delay: -16s;
  -moz-animation-delay: -16s;
  -o-animation-delay: -16s;
  animation-delay: -16s;
  -webkit-transform: translate3d(44vw, 26vh, 43px);
  -moz-transform: translate3d(44vw, 26vh, 43px);
  -ms-transform: translate3d(44vw, 26vh, 43px);
  -o-transform: translate3d(44vw, 26vh, 43px);
  transform: translate3d(44vw, 26vh, 43px);
  background: #26d980;
}
.bubble:nth-child(81) {
  height: 12px;
  width: 12px;
  margin-left: -12px/2;
  margin-top: -12px/2;
  -webkit-animation-delay: -16.2s;
  -moz-animation-delay: -16.2s;
  -o-animation-delay: -16.2s;
  animation-delay: -16.2s;
  -webkit-transform: translate3d(85vw, 71vh, 480px);
  -moz-transform: translate3d(85vw, 71vh, 480px);
  -ms-transform: translate3d(85vw, 71vh, 480px);
  -o-transform: translate3d(85vw, 71vh, 480px);
  transform: translate3d(85vw, 71vh, 480px);
  background: #d92656;
}
.bubble:nth-child(82) {
  height: 21px;
  width: 21px;
  margin-left: -21px/2;
  margin-top: -21px/2;
  -webkit-animation-delay: -16.4s;
  -moz-animation-delay: -16.4s;
  -o-animation-delay: -16.4s;
  animation-delay: -16.4s;
  -webkit-transform: translate3d(88vw, 50vh, 566px);
  -moz-transform: translate3d(88vw, 50vh, 566px);
  -ms-transform: translate3d(88vw, 50vh, 566px);
  -o-transform: translate3d(88vw, 50vh, 566px);
  transform: translate3d(88vw, 50vh, 566px);
  background: #d926af;
}
.bubble:nth-child(83) {
  height: 3px;
  width: 3px;
  margin-left: -3px/2;
  margin-top: -3px/2;
  -webkit-animation-delay: -16.6s;
  -moz-animation-delay: -16.6s;
  -o-animation-delay: -16.6s;
  animation-delay: -16.6s;
  -webkit-transform: translate3d(35vw, 16vh, 578px);
  -moz-transform: translate3d(35vw, 16vh, 578px);
  -ms-transform: translate3d(35vw, 16vh, 578px);
  -o-transform: translate3d(35vw, 16vh, 578px);
  transform: translate3d(35vw, 16vh, 578px);
  background: #d94126;
}
.bubble:nth-child(84) {
  height: 20px;
  width: 20px;
  margin-left: -20px/2;
  margin-top: -20px/2;
  -webkit-animation-delay: -16.8s;
  -moz-animation-delay: -16.8s;
  -o-animation-delay: -16.8s;
  animation-delay: -16.8s;
  -webkit-transform: translate3d(43vw, 1vh, 338px);
  -moz-transform: translate3d(43vw, 1vh, 338px);
  -ms-transform: translate3d(43vw, 1vh, 338px);
  -o-transform: translate3d(43vw, 1vh, 338px);
  transform: translate3d(43vw, 1vh, 338px);
  background: #d94a26;
}
.bubble:nth-child(85) {
  height: 14px;
  width: 14px;
  margin-left: -14px/2;
  margin-top: -14px/2;
  -webkit-animation-delay: -17s;
  -moz-animation-delay: -17s;
  -o-animation-delay: -17s;
  animation-delay: -17s;
  -webkit-transform: translate3d(32vw, 30vh, 472px);
  -moz-transform: translate3d(32vw, 30vh, 472px);
  -ms-transform: translate3d(32vw, 30vh, 472px);
  -o-transform: translate3d(32vw, 30vh, 472px);
  transform: translate3d(32vw, 30vh, 472px);
  background: #d9269a;
}
.bubble:nth-child(86) {
  height: 6px;
  width: 6px;
  margin-left: -6px/2;
  margin-top: -6px/2;
  -webkit-animation-delay: -17.2s;
  -moz-animation-delay: -17.2s;
  -o-animation-delay: -17.2s;
  animation-delay: -17.2s;
  -webkit-transform: translate3d(5vw, 78vh, 74px);
  -moz-transform: translate3d(5vw, 78vh, 74px);
  -ms-transform: translate3d(5vw, 78vh, 74px);
  -o-transform: translate3d(5vw, 78vh, 74px);
  transform: translate3d(5vw, 78vh, 74px);
  background: #d92665;
}
.bubble:nth-child(87) {
  height: 9px;
  width: 9px;
  margin-left: -9px/2;
  margin-top: -9px/2;
  -webkit-animation-delay: -17.4s;
  -moz-animation-delay: -17.4s;
  -o-animation-delay: -17.4s;
  animation-delay: -17.4s;
  -webkit-transform: translate3d(86vw, 81vh, 40px);
  -moz-transform: translate3d(86vw, 81vh, 40px);
  -ms-transform: translate3d(86vw, 81vh, 40px);
  -o-transform: translate3d(86vw, 81vh, 40px);
  transform: translate3d(86vw, 81vh, 40px);
  background: #cad926;
}
.bubble:nth-child(88) {
  height: 16px;
  width: 16px;
  margin-left: -16px/2;
  margin-top: -16px/2;
  -webkit-animation-delay: -17.6s;
  -moz-animation-delay: -17.6s;
  -o-animation-delay: -17.6s;
  animation-delay: -17.6s;
  -webkit-transform: translate3d(32vw, 83vh, 371px);
  -moz-transform: translate3d(32vw, 83vh, 371px);
  -ms-transform: translate3d(32vw, 83vh, 371px);
  -o-transform: translate3d(32vw, 83vh, 371px);
  transform: translate3d(32vw, 83vh, 371px);
  background: #3826d9;
}
.bubble:nth-child(89) {
  height: 14px;
  width: 14px;
  margin-left: -14px/2;
  margin-top: -14px/2;
  -webkit-animation-delay: -17.8s;
  -moz-animation-delay: -17.8s;
  -o-animation-delay: -17.8s;
  animation-delay: -17.8s;
  -webkit-transform: translate3d(55vw, 65vh, 265px);
  -moz-transform: translate3d(55vw, 65vh, 265px);
  -ms-transform: translate3d(55vw, 65vh, 265px);
  -o-transform: translate3d(55vw, 65vh, 265px);
  transform: translate3d(55vw, 65vh, 265px);
  background: #d92629;
}
.bubble:nth-child(90) {
  height: 20px;
  width: 20px;
  margin-left: -20px/2;
  margin-top: -20px/2;
  -webkit-animation-delay: -18s;
  -moz-animation-delay: -18s;
  -o-animation-delay: -18s;
  animation-delay: -18s;
  -webkit-transform: translate3d(100vw, 88vh, 599px);
  -moz-transform: translate3d(100vw, 88vh, 599px);
  -ms-transform: translate3d(100vw, 88vh, 599px);
  -o-transform: translate3d(100vw, 88vh, 599px);
  transform: translate3d(100vw, 88vh, 599px);
  background: #269ad9;
}
.bubble:nth-child(91) {
  height: 13px;
  width: 13px;
  margin-left: -13px/2;
  margin-top: -13px/2;
  -webkit-animation-delay: -18.2s;
  -moz-animation-delay: -18.2s;
  -o-animation-delay: -18.2s;
  animation-delay: -18.2s;
  -webkit-transform: translate3d(85vw, 21vh, 467px);
  -moz-transform: translate3d(85vw, 21vh, 467px);
  -ms-transform: translate3d(85vw, 21vh, 467px);
  -o-transform: translate3d(85vw, 21vh, 467px);
  transform: translate3d(85vw, 21vh, 467px);
  background: #26c7d9;
}
.bubble:nth-child(92) {
  height: 19px;
  width: 19px;
  margin-left: -19px/2;
  margin-top: -19px/2;
  -webkit-animation-delay: -18.4s;
  -moz-animation-delay: -18.4s;
  -o-animation-delay: -18.4s;
  animation-delay: -18.4s;
  -webkit-transform: translate3d(95vw, 65vh, 477px);
  -moz-transform: translate3d(95vw, 65vh, 477px);
  -ms-transform: translate3d(95vw, 65vh, 477px);
  -o-transform: translate3d(95vw, 65vh, 477px);
  transform: translate3d(95vw, 65vh, 477px);
  background: #71d926;
}
.bubble:nth-child(93) {
  height: 26px;
  width: 26px;
  margin-left: -26px/2;
  margin-top: -26px/2;
  -webkit-animation-delay: -18.6s;
  -moz-animation-delay: -18.6s;
  -o-animation-delay: -18.6s;
  animation-delay: -18.6s;
  -webkit-transform: translate3d(61vw, 87vh, 406px);
  -moz-transform: translate3d(61vw, 87vh, 406px);
  -ms-transform: translate3d(61vw, 87vh, 406px);
  -o-transform: translate3d(61vw, 87vh, 406px);
  transform: translate3d(61vw, 87vh, 406px);
  background: #2f26d9;
}
.bubble:nth-child(94) {
  height: 27px;
  width: 27px;
  margin-left: -27px/2;
  margin-top: -27px/2;
  -webkit-animation-delay: -18.8s;
  -moz-animation-delay: -18.8s;
  -o-animation-delay: -18.8s;
  animation-delay: -18.8s;
  -webkit-transform: translate3d(33vw, 91vh, 488px);
  -moz-transform: translate3d(33vw, 91vh, 488px);
  -ms-transform: translate3d(33vw, 91vh, 488px);
  -o-transform: translate3d(33vw, 91vh, 488px);
  transform: translate3d(33vw, 91vh, 488px);
  background: #26d9c1;
}
.bubble:nth-child(95) {
  height: 1px;
  width: 1px;
  margin-left: -1px/2;
  margin-top: -1px/2;
  -webkit-animation-delay: -19s;
  -moz-animation-delay: -19s;
  -o-animation-delay: -19s;
  animation-delay: -19s;
  -webkit-transform: translate3d(56vw, 44vh, 582px);
  -moz-transform: translate3d(56vw, 44vh, 582px);
  -ms-transform: translate3d(56vw, 44vh, 582px);
  -o-transform: translate3d(56vw, 44vh, 582px);
  transform: translate3d(56vw, 44vh, 582px);
  background: #d9d626;
}
.bubble:nth-child(96) {
  height: 24px;
  width: 24px;
  margin-left: -24px/2;
  margin-top: -24px/2;
  -webkit-animation-delay: -19.2s;
  -moz-animation-delay: -19.2s;
  -o-animation-delay: -19.2s;
  animation-delay: -19.2s;
  -webkit-transform: translate3d(74vw, 72vh, 268px);
  -moz-transform: translate3d(74vw, 72vh, 268px);
  -ms-transform: translate3d(74vw, 72vh, 268px);
  -o-transform: translate3d(74vw, 72vh, 268px);
  transform: translate3d(74vw, 72vh, 268px);
  background: #2671d9;
}
.bubble:nth-child(97) {
  height: 6px;
  width: 6px;
  margin-left: -6px/2;
  margin-top: -6px/2;
  -webkit-animation-delay: -19.4s;
  -moz-animation-delay: -19.4s;
  -o-animation-delay: -19.4s;
  animation-delay: -19.4s;
  -webkit-transform: translate3d(28vw, 71vh, 86px);
  -moz-transform: translate3d(28vw, 71vh, 86px);
  -ms-transform: translate3d(28vw, 71vh, 86px);
  -o-transform: translate3d(28vw, 71vh, 86px);
  transform: translate3d(28vw, 71vh, 86px);
  background: #d3d926;
}
.bubble:nth-child(98) {
  height: 14px;
  width: 14px;
  margin-left: -14px/2;
  margin-top: -14px/2;
  -webkit-animation-delay: -19.6s;
  -moz-animation-delay: -19.6s;
  -o-animation-delay: -19.6s;
  animation-delay: -19.6s;
  -webkit-transform: translate3d(52vw, 32vh, 260px);
  -moz-transform: translate3d(52vw, 32vh, 260px);
  -ms-transform: translate3d(52vw, 32vh, 260px);
  -o-transform: translate3d(52vw, 32vh, 260px);
  transform: translate3d(52vw, 32vh, 260px);
  background: #d926d3;
}
.bubble:nth-child(99) {
  height: 17px;
  width: 17px;
  margin-left: -17px/2;
  margin-top: -17px/2;
  -webkit-animation-delay: -19.8s;
  -moz-animation-delay: -19.8s;
  -o-animation-delay: -19.8s;
  animation-delay: -19.8s;
  -webkit-transform: translate3d(5vw, 92vh, 36px);
  -moz-transform: translate3d(5vw, 92vh, 36px);
  -ms-transform: translate3d(5vw, 92vh, 36px);
  -o-transform: translate3d(5vw, 92vh, 36px);
  transform: translate3d(5vw, 92vh, 36px);
  background: #26d965;
}
.bubble:nth-child(100) {
  height: 15px;
  width: 15px;
  margin-left: -15px/2;
  margin-top: -15px/2;
  -webkit-animation-delay: -20s;
  -moz-animation-delay: -20s;
  -o-animation-delay: -20s;
  animation-delay: -20s;
  -webkit-transform: translate3d(84vw, 37vh, 329px);
  -moz-transform: translate3d(84vw, 37vh, 329px);
  -ms-transform: translate3d(84vw, 37vh, 329px);
  -o-transform: translate3d(84vw, 37vh, 329px);
  transform: translate3d(84vw, 37vh, 329px);
  background: #a326d9;
}
@media screen and (max-width: 999px) {
  .bubble-content {
    left: 0;
  }
}
@media screen and (min-width: 1000px) {
  .bubble-content {
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    height: 10px;
  }
}
